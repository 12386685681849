<template>
  <div class="graph-container">
    <div class="graph-container__inner">
      <div class="graph-container__head">
        <div class="graph-container__title"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'dashboard_expressAndUsualOrders',
        ])"></div>
          {{$t('dashboard_expressAndUsualOrders.localization_value.value')}}
        </div>
        <div class="graph-container__period"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'dashboard_period',
        ])"></div>
          <DatePickerDefault
              class="date-picker-time-custom"
              :label="$t('dashboard_period.localization_value.value')"
              v-model="date"
          >
            <template slot="body">
              <date-picker
                  v-model="date"
                  ref="datePicker"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'YYYY-MM-DD'"
                  :placeholder="'YYYY-MM-DD'"
                  @change="initChart"
                  range
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>
      </div>
      <div class="graph-container__body">
        <vue-custom-scrollbar
            class="graph-container__wrapper"
        >
          <Bar
              class="test123"
              v-if="loadingComplete"
              :chart-id="'bar-chart'"
              :chart-options="chartOptions"
              :chart-data="chartData"
              :height="height"
              :width="width"
          />
          <div v-else class="graph-container__preloader">
            <DotsShadowPreloader
            />
          </div>
        </vue-custom-scrollbar>
        <div class="graph-container__legend"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'dashboard_chartExpress',
          'dashboard_chartFBA',
          'dashboard_chartFBMConsolidation',
          'dashboard_chartFBMWarehouse',
          'dashboard_chartFBMLabel',
        ])"></div>
          <div
              class="graph-container__legend-item"
              v-for="(item, index) in chartData.datasets"
              :key="index"
          >
            <div :style="`background-color: ${item.backgroundColor};`" class="graph-container__legend-item-ico"></div>
            <div class="graph-container__legend-item-text">{{ item.label }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault.vue"
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import {userSettingsMixin} from "../../../../../mixins/globalMixins/userSettingsMixin";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "GraphUsualOrders",
  components: {
    DotsShadowPreloader,
    DatePickerDefault,
    DatePicker,
    Bar,
    vueCustomScrollbar
  },

  mixins: [userSettingsMixin],

  watch: {
    loadUserAuthorizedData() {
      this.initChart()
    },
  },

  mounted() {
    if(this.loadUserAuthorizedData){
      this.initChart()
    }
  },

  data(){
    return {
      date: [
        this.$moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD")
      ],
      loadingComplete: false,
      labels: [],
      datasets: [],
      chartData: [],
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              color: "#8F7A61",
              font: {
                size: 14,
              },
              stepSize: 1,
              beginAtZero: true
            }
          },
          x: {
            ticks: {
              color: "#8F7A61",
              font: {
                size: 14
              },
              stepSize: 1,
              beginAtZero: true
            }
          }
        },
        plugins:
          {
            legend: {
              display: false
            },
          }
        ,
      },
      height: 350,
      width: 1200,
    }
  },

  methods: {
    initChart() {
      this.loadingComplete = false

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('user_id', this.$store.getters.GET_COMMON_AUTHORIZED.user?.id)
      myQuery.whereIn('between_created_at', this.date)

      //reset datasets
      this.datasets = []
      this.labels = this.getBetweenDates(...this.date)

      this.width = this.labels.length > 18 ? this.labels.length * 64 + 30 : 1200
      this.chartOptions.responsive = this.labels.length < 18

      url = url + myQuery.limit(100000).page(0).url().split('?')[1]

      this.$store.dispatch('fetchChartsOrders', url).then(response => {
        console.log('chart orders', response);
        let respData = this.getRespData(response)

        this.datasets.push({
          label: this.$t('dashboard_chartFBMWarehouse.localization_value.value'),
          backgroundColor: '#F99746',
          data: this.generateDatasetData(this.labels, respData?.count_orders_fbm_sklad_create_label)
        })
        this.datasets.push({
          label: this.$t('dashboard_chartFBMConsolidation.localization_value.value'),
          backgroundColor: '#739AF0',
          data: this.generateDatasetData(this.labels, respData?.count_orders_fbm_consolidation)
        })

        if (this.getUserFBMCreateLabelSetting) {
          this.datasets.push({
            label: this.$t('dashboard_chartFBMLabel.localization_value.value'),
            backgroundColor: '#9B51E0',
            data: this.generateDatasetData(this.labels, respData?.count_orders_fbm_user_create_label)
          })
        }

        this.datasets.push({
          label: this.$t('dashboard_chartExpress.localization_value.value'),
          backgroundColor: '#D71635',
          data: this.generateDatasetData(this.labels, respData?.count_orders_express)
        })

        if (this.$store.getters.getUserProfile.fulfilment_by_amazon === 1) {
          this.datasets.push({
            label: this.$t('dashboard_chartFBA.localization_value.value'),
            backgroundColor: '#F2C94C',
            data: this.generateDatasetData(this.labels, respData?.count_orders_fba)
          })
        }




        this.chartData = {
          labels: this.labels.map(item => {
            return this.$moment(item).format('DD MMM')
          }),
          datasets: this.datasets
        }

        this.loadingComplete = true
      }).catch(error => this.createErrorLog(error))
    },

    generateDatasetData(labels, valuesArray) {
      return labels.map(item => {
        let foundItem = valuesArray.find(findItem => {
          return findItem.date === item
        })

        return foundItem ? foundItem.count : 0
      })
    },
  }
}
</script>

<style lang="scss">
@import "../../../../../scss/colors";

.graph-container__wrapper {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    border: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $mainBg;
    margin-right: 22px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accent;
    opacity: .5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $accent;
    opacity: .5;
  }

  .ps__thumb-x{
    background: $accent;
    opacity: .5;
    height: 4px;
    bottom: 1px;
  }

  &.ps .ps__rail-x {
    opacity: 1;
    height: 6px;
    background: $mainBg;
    margin-right: 22px;

    &.ps--clicking {
      .ps__thumb-x {
        height: 4px;
        background-color: $accent;
      }
    }
  }

  .ps__rail-x:hover {
    background: $mainBg;

    &>.ps__thumb-x {
      background: $accent;
      height: 4px;
    }
  }
}

.graph-container__preloader {
  height: 350px;
}

.graph-container__legend {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-item {
    padding: 0 12px;
    display: flex;
    align-items: center;

    &-ico {
      width: 6px;
      height: 6px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &-text {
      color: #8F7A61;
    }
  }
}

@media (max-width: 767px) {
  .graph-container__legend {
    display: block;
    padding-left: 20px;

    &-item {
      padding: 5px 0;
    }
  }
}
</style>
