var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"graph-container"},[_c('div',{staticClass:"graph-container__inner"},[_c('div',{staticClass:"graph-container__head"},[_c('div',{staticClass:"graph-container__title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'dashboard_expressAndUsualOrders',
      ])}}}),_vm._v(" "+_vm._s(_vm.$t('dashboard_expressAndUsualOrders.localization_value.value'))+" ")]),_c('div',{staticClass:"graph-container__period",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'dashboard_period',
      ])}}}),_c('DatePickerDefault',{staticClass:"date-picker-time-custom",attrs:{"label":_vm.$t('dashboard_period.localization_value.value')},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('template',{slot:"body"},[_c('date-picker',{ref:"datePicker",attrs:{"valueType":"format","lang":_vm.$store.getters.getDatePickerTranslations[_vm.$store.getters.GET_LANG],"format":'YYYY-MM-DD',"placeholder":'YYYY-MM-DD',"range":""},on:{"change":_vm.initChart},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],2)],1)]),_c('div',{staticClass:"graph-container__body"},[_c('vue-custom-scrollbar',{staticClass:"graph-container__wrapper"},[(_vm.loadingComplete)?_c('Bar',{staticClass:"test123",attrs:{"chart-id":'bar-chart',"chart-options":_vm.chartOptions,"chart-data":_vm.chartData,"height":_vm.height,"width":_vm.width}}):_c('div',{staticClass:"graph-container__preloader"},[_c('DotsShadowPreloader')],1)],1),_c('div',{staticClass:"graph-container__legend",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'dashboard_chartExpress',
        'dashboard_chartFBA',
        'dashboard_chartFBMConsolidation',
        'dashboard_chartFBMWarehouse',
        'dashboard_chartFBMLabel',
      ])}}}),_vm._l((_vm.chartData.datasets),function(item,index){return _c('div',{key:index,staticClass:"graph-container__legend-item"},[_c('div',{staticClass:"graph-container__legend-item-ico",style:(`background-color: ${item.backgroundColor};`)}),_c('div',{staticClass:"graph-container__legend-item-text"},[_vm._v(_vm._s(item.label))])])})],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }