<template>
  <div class="graph-container">
    <div class="graph-container__inner">
      <div class="graph-container__head">
        <div class="graph-container__title"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'dashboard_financeStatistics',
        ])"></div>
          {{$t('dashboard_financeStatistics.localization_value.value')}}
        </div>
        <div class="graph-container__period"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'dashboard_period',
        ])"></div>
          <DatePickerDefault
              class="date-picker-time-custom"
              :label="$t('dashboard_period.localization_value.value')"
              v-model="date"
          >
            <template slot="body">
              <date-picker
                  v-model="date"
                  ref="datePicker"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'YYYY-MM-DD'"
                  :placeholder="'YYYY-MM-DD'"
                  @change="initChart"
                  range
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>
      </div>
      <div class="graph-container__body">
        <vue-custom-scrollbar
            class="graph-container__wrapper"
        >
          <LineChartGenerator
              class="test123"
              v-if="loadingComplete"
              :chart-id="'line-chart'"
              :chart-options="chartOptions"
              :chart-data="chartData"
              :height="height"
              :width="width"
          />
          <div v-else class="graph-container__preloader">
            <DotsShadowPreloader
            />
          </div>
        </vue-custom-scrollbar>

        <div class="graph-container__legend">
          <div
              class="graph-container__legend-item"
              v-for="(item, index) in chartData.datasets"
              :key="index"
          >
            <div :style="`background-color: ${item.borderColor};`" class="graph-container__legend-item-ico"></div>
            <div class="graph-container__legend-item-text">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault.vue"
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js';
import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css"

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: "GraphFinanceStatistics",
  components: {
    DotsShadowPreloader,
    DatePickerDefault,
    DatePicker,
    LineChartGenerator,
    vueCustomScrollbar
  },

  watch: {
    loadUserAuthorizedData() {
      this.initChart()
    },
  },

  mounted() {
    if(this.loadUserAuthorizedData){
      this.initChart()
    }
  },

  data(){
    return {
      date: [
        this.$moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD"),
        this.$moment(new Date()).format("YYYY-MM-DD")
      ],
      loadingComplete: false,
      labels: [],
      datasets: [],
      chartData: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              color: "#8F7A61",
              font: {
                size: 14,
              },
              stepSize: 1,
              beginAtZero: true
            }
          },
          x: {
            ticks: {
              color: "#8F7A61",
              font: {
                size: 14
              },
              stepSize: 1,
              beginAtZero: true
            }
          }
        },
        plugins:
            {
              legend: {
                display: false
              },
            }
        ,
      },
      height: 350,
      width: 1200,

      colorsList: [
        '#F99746',
        '#739AF0',
        '#9B51E0',
        '#F2C94C',
        '#219653',
        '#D71635',
        '#808000',
        '#0000FF',
        '#48D1CC',
        '#00FF7F',
        '#BDB76B',
        '#FFD700',
        '#FF6347',
        '#B22222',
        '#4B0082',
        '#8B008B',
        '#FF1493',
        '#2F4F4F',
        '#A9A9A9',
        '#FAEBD7',
        '#B8860B',
        '#6495ED',
        '#5F9EA0',
        '#7FFFD4',
      ]
    }
  },

  methods: {
    initChart() {
      this.loadingComplete = false

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      console.log(1111111111, this.$store.getters.GET_COMMON_AUTHORIZED.user?.id);
      myQuery.where('user_id', this.$store.getters.GET_COMMON_AUTHORIZED.user?.id)
      myQuery.whereIn('between_created_at', this.date)

      //reset datasets
      this.datasets = []
      this.labels = this.getBetweenDates(...this.date)

      this.width = this.labels.length > 23 ? this.labels.length * 64 + 30 : 1500
      this.chartOptions.responsive = this.labels.length < 23

      url = url + myQuery.limit(100000).page(0).url().split('?')[1]

      this.$store.dispatch('fetchChartsTransactions', url).then(response => {
        let respData = this.getRespData(response)

        this.datasets = respData.map((item, index) => {
          return {
            label: item.shop.name,
            backgroundColor: this.colorsList[index > 23 ? index - 24: index],
            borderColor: this.colorsList[index > 23 ? index - 24: index],
            data: this.generateDatasetData(this.labels, item.shop.count_transactions ? item.shop.count_transactions : [])
          }
        })

        let allZerosFlag = true
        this.datasets.forEach(item => {
          item.data.forEach(dataItem => {
            if (dataItem > 0) {
              allZerosFlag = false
            }
          })
        })

        if (allZerosFlag) {
          this.datasets = this.datasets.map(item => {
            return {
              ...item,
              data: [],
            }
          })
        }

        if (respData.length === 0) {
          this.datasets = [{
            label: '',
            backgroundColor: '',
            borderColor: '',
            data: []
          }]
        }

        this.chartData = {
          labels: this.labels.map(item => {
            return this.$moment(item).format('DD MMM')
          }),
          datasets: this.datasets
        }

        this.loadingComplete = true
      }).catch(error => this.createErrorLog(error))
    },

    generateDatasetData(labels, valuesArray) {
      return labels.map(item => {
        let foundItem = valuesArray.find(findItem => {
          return findItem?.date === item
        })

        return foundItem ? foundItem.count : 0
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../scss/colors";

.graph-container__wrapper {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    border: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $mainBg;
    margin-right: 22px;
  }

  &::-webkit-scrollbar-thumb {
    background: $accent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $accent;
    opacity: .5;
  }
}

.graph-container__preloader {
  height: 350px;
}

.graph-container__legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &-item {
    padding: 5px 12px;
    display: flex;
    align-items: center;

    &-ico {
      width: 6px;
      height: 6px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &-text {
      color: #8F7A61;
    }
  }
}

@media (max-width: 767px) {
  .graph-container__legend {
    padding-left: 20px;
  }
}
</style>
