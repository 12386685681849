<template>
  <div class="detail-page notification-page">
    <div class="detail-page__right">

        <GraphUsualOrders/>

        <div class="mt-4">
          <GraphFinanceStatistics/>
        </div>

    </div>
  </div>
</template>

<script>


  import GraphUsualOrders from "@/components/modules/DashboardModule/components/GraphUsualOrders/GraphUsualOrders.vue";
  import GraphFinanceStatistics
    from "@/components/modules/DashboardModule/components/GraphFinanceStatistics/GraphFinanceStatistics.vue";
  export default {
    name: "GraphicsStatisticSection",
    components: {
      GraphFinanceStatistics,
      GraphUsualOrders

    },

    props: [

    ],

    data(){
      return {

      }
    },

    methods: {

    },
  }
</script>

<style lang="scss" scoped>

</style>
